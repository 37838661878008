import React from "react";
import { Layout, ProjectSection, Hero } from "components";
import primaryHeroBackground from "../images/hero-background.svg";

const Index = () => (
  <Layout>
    <Hero backgroundImage={primaryHeroBackground} />
    <ProjectSection />
  </Layout>
);

export default Index;
